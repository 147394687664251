<template>
  <div ref="stickyContainer">
    <div
      class="flex flex-col items-center"
      :class="{
        'fixed left-0 bottom-0 z-50 !m-0 w-full bg-blue-hover px-4 py-2 pt-4 text-center lg:pt-2':
          safeData.position === 'fixed',
        'fixed left-0 left-0 top-0 z-50 !m-0 w-full bg-blue-hover py-2 pt-4 lg:py-4': isSticky,
      }"
    >
      <Title custom-style="text-center font-e1234 lg:text-2xl" :level="2">
        <CountUpDisplay
          :end-val="computedCountValue"
          :start-val="prevCount"
          :duration="safeData.speed"
          :options="{
            enableScrollSpy: true,
            scrollSpyOnce: safeData.startOnce,
            separator: '',
          }"
          custom-class="text-center"
        />
      </Title>
      <div class="mb-2 mt-2 w-[150px] border-b-[2px] border-blue lg:mb-1 lg:mt-1" />

      <p
        class="font-sans text-lg font-bold leading-6 text-black lg:text-lg"
        v-if="safeData.titleEnd"
      >
        {{ safeData.titleEnd }}
      </p>

      <a
        v-if="safeData.anchor"
        :href="`#${safeData.anchor}`"
        class="relative inline-block rounded-md px-8 py-3 text-center font-sans text-base font-bold uppercase outline-0 transition hover:text-blue lg:px-14 lg:py-4 lg:text-xl"
        >{{ safeData.anchorTitle }}</a
      >
    </div>
  </div>
</template>

<script setup lang="ts">
import CountUpDisplay from '../../elements/CountUpDisplay.vue'
import Title from '../../atoms/Title.vue'

import { computed, nextTick, onMounted, onUnmounted, ref } from 'vue'

const isClient = typeof window !== 'undefined' // Cl running in the browser

interface DataProps {
  // The final count value. If provided, the counter will not calculate dynamically.
  endValue?: number

  // Animation duration for CountUp component (default: 2 seconds).
  speed?: number

  // Prefix text to display before the count.
  titleStart?: string

  // Suffix text to display after the count.
  titleEnd?: string

  //  If `true`, the counter runs only once and does not continuously update.
  startOnce?: boolean

  /**
   * The date-time from which the counter should start counting.
   * If string: ISO 8601 date (e.g., "2025-03-14T04:47:39Z").
   * If number: Direct count start value.
   */
  startValue?: string | number

  /**
   * Rate of the count increase per second.
   * Example: If 3.64583333 events happen per second, use that value.
   */
  ratePerSecond?: number

  /**
   * The update interval for the counter in milliseconds.
   * Lower values make the counter update more frequently but may affect performance.
   * Default: 50ms for smooth animation.
   */
  updateIntervalMs?: number
  position?: 'sticky' | 'fixed' | undefined

  anchor?: string
  anchorTitle?: string
}

interface Props {
  data: DataProps
}

const props = defineProps<Props>()

const computedCount = ref(0)
const prevCount = ref(0)
let intervalId: ReturnType<typeof setInterval> | null = null

const safeData = computed(() => ({
  endValue: props.data?.endValue,
  speed: props.data?.speed ?? 2,
  titleStart: props.data?.titleStart ?? '',
  titleEnd: props.data?.titleEnd ?? '',
  startOnce: props.data?.startOnce ?? false,
  startValue: props.data?.startValue ?? '2025-03-14T04:47:39Z',
  ratePerSecond: props.data?.ratePerSecond ?? 3.64583333,
  updateIntervalMs: props.data?.updateIntervalMs ?? 50,
  position: props.data?.position ?? '',
  anchor: props.data?.anchor ?? '',
  anchorTitle: props.data?.anchorTitle ?? '',
}))

const computedCountValue = computed(() =>
  safeData.value.endValue !== undefined ? safeData.value.endValue : computedCount.value,
)

const isSticky = ref(false)
const stickyContainer = ref<HTMLElement | null>(null)

// Convert rate to per-millisecond for precise updates
const ratePerMillisecond = safeData.value.ratePerSecond / 1000

const isDateString = (value: string | number) =>
  typeof value === 'string' && !isNaN(Date.parse(value))

// Determine starting count value
const initialCount = ref(0)
const startTime = ref<number | null>(null)

if (isDateString(safeData.value.startValue)) {
  startTime.value = new Date(safeData.value.startValue).getTime()
  initialCount.value = 0
} else {
  startTime.value = Date.now()
  initialCount.value = Number(safeData.value.startValue ?? 0)
}

// Updates the counter based on elapsed time since START_VALUE.
const updateCount = () => {
  if (safeData.value.endValue !== undefined) return // Stop if endValue is provided
  const now = Date.now()
  const elapsedMs = startTime.value ? now - startTime.value : 0
  const newCount = initialCount.value + Math.floor(elapsedMs * ratePerMillisecond)

  if (newCount !== computedCount.value) {
    prevCount.value = computedCount.value
    computedCount.value = newCount
  }
}

const startCounting = () => {
  if (safeData.value.endValue !== undefined) return // Stop if endValue is provided
  updateCount() // Initial calculation
  if (!safeData.value.startOnce && !intervalId) {
    intervalId = setInterval(updateCount, safeData.value.updateIntervalMs)
  }
}

// Stops counting when the element is out of the viewport.
const stopCounting = () => {
  if (intervalId) {
    clearInterval(intervalId)
    intervalId = null
  }
}

const fixedScrollThreshold = 200

const handleScroll = () => {
  if (!stickyContainer.value) return
  if (safeData.value.position === 'sticky') {
    const rect = stickyContainer.value.getBoundingClientRect()
    isSticky.value = rect.top <= 0
  }
}

onMounted(async () => {
  if (!isClient) return // Ensure it runs only in the browser
  await nextTick()
  if (safeData.value.position) {
    window.addEventListener('scroll', handleScroll)
  }
  startCounting()
})

onUnmounted(() => {
  if (!isClient) return // Prevent errors in SSR
  if (safeData.value.position) {
    window.removeEventListener('scroll', handleScroll)
  }
  stopCounting()
})
</script>
